import './bootstrap';
import jBob from 'jbob';

import 'jquery-ui/ui/data.js';
import 'jquery-ui/ui/scroll-parent.js';
import 'jquery-ui/ui/widget.js';
import 'jquery-ui/ui/widgets/mouse.js';
import 'jquery-ui/ui/widgets/sortable.js';

import.meta.glob([
    '../images/**',
]);

$(document).ready(function($) {
    let j = new jBob;

    j.init({
        initFunction: function(container) {
            $('input[required], select[required], textarea[required]', container).each((index, node) => {
                let id = $(node).attr('id');
                $('label[for="' + id + '"]').addClass('mandatory');
            });
        }
    });

    $('.load-group-file').click(function() {
        var id = $(this).data('group');
        $('#loadFile input[name=group_id]').val(id);
    });

    if ($('#triggerform').length > 0) {
        $('#triggerform').submit();
    }

    $('#textfilter').keyup(function() {
        var t = $(this).val().toLowerCase();

        if (t == '') {
            $('.filteratable tbody tr').show();
        } else {
            $('.filteratable tbody tr:visible').each(function() {
                var row = $(this);
                var a = row.find('td').text().toLowerCase();

                if (a.indexOf(t) == -1) {
                    row.hide();
                }
                else {
                    row.show();
                }
            });
        }
    });

    $('#buttonfilter input[type=radio]').change(function() {
        if ($(this).prop('checked') == false)
            return;

        var t = $(this).val();
        var name = $('#buttonfilter').attr('data-filter-attribute');

        if (t == 'all') {
            $('.filteratable tbody tr').show();
        } else {
            $('.filteratable tbody tr[data-filter-' + name + '=' + t + ']').show();
            $('.filteratable tbody tr[data-filter-' + name + '!=' + t + ']').hide();
        }
    });

    $('body').on('change', '.checksize', function() {
        if ($(this).get(0).files[0].size > 5000000) {
            alert('Il file allegato è troppo grande');
            $(this).val('');
        };
    });

    $('form.auto-form').on('change', 'select', function() {
        $(this).closest('form').submit();
    });

    $('.trigger_sender').change(function() {
        if ($(this).prop('checked')) {
            var value = $(this).val();
            $('.trigger_target').filter('[data-display-on*=' + value + ']').prop('hidden', false).find('.mandatory input').prop('required', true);
            $('.trigger_target').filter(':not([data-display-on*=' + value + '])').prop('hidden', true).find('.mandatory input').prop('required', false);
        }
    });

    $('body').on('submit', '.dynamic-form', function(e) {
        e.preventDefault();
        var form = $(this);
        form.find('button[type=submit]').prop('disabled', true);

        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize(),
            success: function(data) {
                var box = form.closest('.info-box');

                $('html, body').animate({
                    scrollTop: box.offset().top
                }, 1000);

                box.replaceWith(data);
            },
            error: function() {
                alert('I dati immessi non sono validi');
                form.find('button[type=submit]').prop('disabled', false);
            }
        });

        return false;
    });

    $('body').on('jb-before-async-fetch', '.async-modal', (e) => {
        $(e.currentTarget).addClass('disabled').prop('disabled', true);
    });

    $('body').on('jb-after-async-fetch', '.async-modal', (e) => {
        $(e.currentTarget).removeClass('disabled').prop('disabled', false);
    });

    $('.sortable').sortable({
        items: 'tr',
        handle: '.handle',
    });

    $('.selectable_expiration').change((e) => {
        let node = $(e.currentTarget);
        if (node.prop('checked')) {
            let date = node.attr('data-expiration-string');
            $('.selected_expiration').text(date);
        }
    });

    $('#editUserRenew form').submit(function(e) {
        e.preventDefault();
        e.stopPropagation();

        let email = $(this).find('[name=temp_email]').val();
        let city = $(this).find('[name=temp_city]').val();
        let province = $(this).find('[name=temp_province]').val();
        let address = $(this).find('[name=temp_address]').val();
        let zip = $(this).find('[name=temp_zip]').val();
        let actual_form = $('#renewForm');

        actual_form.find('[name=email]').val(email);
        actual_form.find('.email').text(email);
        actual_form.find('[name=city]').val(city);
        actual_form.find('.city').text(city);
        actual_form.find('[name=province]').val(province);
        actual_form.find('.province').text(province);
        actual_form.find('[name=address]').val(address);
        actual_form.find('.address').text(address);
        actual_form.find('[name=zip]').val(zip);
        actual_form.find('.zip').text(zip);

        $(this).closest('.modal').modal('hide');
    });

    $('form.courses select[name=hq]').change(function(e) {
        let selected = $(this).val();
        if (selected == 'Altro') {
            $('input[name=city]').prop('required', true).prop('hidden', false).closest('div').prop('hidden', false);
        }
        else {
            $('input[name=city]').prop('required', false).prop('hidden', true).closest('div').prop('hidden', true);
        }
    });

    $('form.courses').submit(function(e) {
        e.preventDefault();
        var form = $(this);
        form.find('button[type=submit]').prop('disabled', true);

        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize(),
            success: function(data) {
                var box = form.closest('.info-box');

                $('html, body').animate({
                    scrollTop: box.offset().top
                }, 1000);

                box.replaceWith(data);
            }
        });

        return false;
    });
});
